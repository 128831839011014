<template>
    <modal class="unionSelectModel" :isShow="isShow">
      <div class="header">桌台选择</div>
      <div class="modal-body">
        <div class="desk content clearfix" style="width: 100%;height: 100%;overflow: auto;">
            <div v-if="tablelist">
                <div>
                    <div class="desk-box opening" v-for="item in tablelist" :key="item" @click="DeskMergeClick(item)"  >
                        <p class="title">{{item.Eat_DeskName}}</p>
                    </div> 
                </div>
            </div>
        </div>
      </div>
      <div class="footer-box">
          <button class="btn btn-cancel" @click="hide()">取消</button>
      </div>
    </modal>
  </template>
  
  <script>
  export default {
      name:'unionSelectModel',
      props:{
        isShow:Boolean,
          /**传入账单数据 */
        billInfo:Object,
        
      },
      data(){
          return{
            tablelist:[]
          }
      },
      watch:{
          isShow(value){
              if(value==true){//显示弹层时 初始化数据
                this.loadData();
              }
          }
      },
      mounted(){
          this.$nextTick(()=>{
              this.loadData();
          })
      },
      methods:{
        /**加载桌台*/
        loadData(){
            this.tablelist=[]
            this.billInfo.OrderTitles.forEach(item=>{
                this.tablelist.push(item)
            })
        },
        //选择的桌台
        DeskMergeClick(item){
            this.$emit("unionConfirm",item);
        },
        hide(){
            this.$emit("closeModel");
        }
      }
  }
  </script>
  <style lang="scss">
     @import "./unionSelectModel.scss"
  </style>